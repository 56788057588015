import React from 'react';

interface Props {
  id?: string;
  title: string | null;
  dateTimeIntervals: { from: string; to: string }[] | null;
  address: string | null;
  locationName: string | null;
  coordinates: {
    longitude: number;
    latitude: number;
  } | null;
}

const SchemaEvent = ({ id = '', title, dateTimeIntervals, address, locationName, coordinates }: Props) => {
  const structuredData = `{
    "@context": "https://schema.org",
    "@type": "${dateTimeIntervals.length > 0 ? 'EventSeries' : 'Event'}",
    "name": "${title}",
    "location": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "name": "${locationName}",
        "streetAddress": "${address}"
      },
      "latitude": "${coordinates?.latitude}",
      "longitude": "${coordinates?.longitude}"
    }
    ${
      dateTimeIntervals.length === 1
        ? `,
      "startDate": "${dateTimeIntervals[0].from}", 
      "endDate": "${dateTimeIntervals[0].to}"`
        : ''
    } 
    }`;

  return (
    <script
      type="application/ld+json"
      key={`centerJSON-${id}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: structuredData.replaceAll(',,', ',') }}
    />
  );
};

export default SchemaEvent;
