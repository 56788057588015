import { Text, TextVariant } from '@naf/text';
import { spacing, themeLight } from '@naf/theme';
import { ArrowForward } from '@styled-icons/material/ArrowForward';
import React from 'react';
import styled from 'styled-components';
import { StyledLink } from '../../../components/styled-link/StyledLink';

type Props = {
  variant: TextVariant;
  text: string;
  to: string;
  subText?: string;
  arrowSize?: number;
};

const StyledText = styled(Text)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HoverWrap = styled.span`
  background-size: 0 2px;
  background-position: 0 100%;
  background-image: linear-gradient(
    90deg,
    ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.active
        : themeLight.componentColors.interactiveElement.active},
    ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.active
        : themeLight.componentColors.interactiveElement.active}
  );
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
  text-decoration: none;
  display: inline;
  margin-right: ${spacing.space4};
`;

const StyledArrow = styled(ArrowForward)`
  transition: 0.4s;
`;

const CustomStyledLink = styled(StyledLink)`
  display: inline-block;

  &:hover {
    ${HoverWrap} {
      background-size: 100% 2px;
    }
    ${StyledArrow} {
      transform: translateX(${spacing.space12});
    }
  }
`;

const SubText = styled(Text)`
  margin: ${spacing.space8} 0;
`;

const ArrowHoverLink = ({ variant, text, to, subText, arrowSize = 32 }: Props) => (
  <div>
    <CustomStyledLink to={to}>
      <StyledText variant={variant}>
        <HoverWrap>{text}</HoverWrap>
        <StyledArrow size={arrowSize} />
      </StyledText>
    </CustomStyledLink>
    {subText && <SubText variant={TextVariant.BodyText}>{subText}</SubText>}
  </div>
);

export default ArrowHoverLink;
