import { ButtonLink } from '@naf/button-link';
import { TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import { getYear, parseISO } from 'date-fns';
import { Place } from '@styled-icons/material-outlined/Place';
import { CalendarToday } from '@styled-icons/material-outlined/CalendarToday';
import React from 'react';
import styled from 'styled-components';
import { createEventTimeString } from '../../../utils/createNorwegianDateString';
import S from '../Styles';

type Props = {
  dateTimeIntervals: {
    from: string;
    to: string;
  }[];
  locationName: string;
  locationAddress: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  priceNotMember?: number;
  priceMember?: number;
};

const EventDetails = ({
  dateTimeIntervals,
  locationName,
  locationAddress,
  coordinates,
  priceMember,
  priceNotMember,
}: Props) => (
  <EventWrapper>
    <S.Time>
      <CalendarToday height={22} width={20} />
      {dateTimeIntervals.map(({ from, to }, index) => {
        if (index === 0) {
          return (
            <S.TextNoMargin key={`${from}-${to}`} tag="span" variant={TextVariant.BodyTextSubHeader}>
              {createEventTimeString(from, to)}
            </S.TextNoMargin>
          );
        }
        return (
          <S.TextIconMargin key={`${from}-${to}`} variant={TextVariant.BodyTextSubHeader}>
            {createEventTimeString(from, to)}
          </S.TextIconMargin>
        );
      })}
      <S.TextIconMargin variant={TextVariant.BodyTextSubHeader}>
        {getYear(parseISO(dateTimeIntervals[0].from))}
      </S.TextIconMargin>
    </S.Time>
    <S.Location>
      <Place height={20} width={20} />
      <S.TextNoMargin tag="span" variant={TextVariant.BodyTextSubHeader}>
        {locationName}
      </S.TextNoMargin>
      <S.TextIconMargin tag="p" variant={TextVariant.BodyTextSubHeader}>
        {locationAddress}
      </S.TextIconMargin>
      <MapButton
        target="_blank"
        href={`http://www.google.com/maps/place/${coordinates.latitude}, ${coordinates.longitude}`}
        text="Se kart"
        size="small"
      />
      {priceMember != null && (
        <S.TextIconMargin tag="p" variant={TextVariant.BodyTextSubHeader}>
          {`Pris for medlem: ${priceMember === 0 ? 'Gratis' : `${priceMember},-`}`}
        </S.TextIconMargin>
      )}
      {!!priceNotMember && (
        <S.TextIconMargin tag="p" variant={TextVariant.BodyTextSubHeader}>
          {`Pris for ikke-medlem: ${priceNotMember},-`}
        </S.TextIconMargin>
      )}
    </S.Location>
  </EventWrapper>
);

const MapButton = styled(ButtonLink)`
  margin: ${spacing.space8} 0 ${spacing.space32} 28px;
`;

const EventWrapper = styled.div`
  margin-bottom: ${spacing.space64};
`;

export default EventDetails;
