import { SanityBlock } from '@dtp/membership-service-types/dist/sanity/sanity';
import { FormField } from '@dtp/membership-service-types';
import { DocumentTypeLabel } from './testType';

type FormFieldWithTracking = FormField & {
  isTrackable: boolean;
  trackingId: string;
};

export interface EventType {
  id: string;
  type: DocumentTypeLabel.event;
  title: string;
  slug: string;
  clubNumber: string;
  phoneNumber: string;
  email: string;
  facebook: any;
  address: {
    region: string;
    address: string;
    postalCode: string;
    postalPlace: string;
  };
  organizer: { title: string; type: string; slug: string };
  link: {
    data: {
      slug: string;
    };
  };
  image: {
    source: string;
    url: string;
    caption: string;
    name: string;
  };
  ingress: string;
  seoConfig: any;
  dateTimeIntervals: { from: string; to: string }[];
  locationName: string;
  locationAddress: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  availabilityInfo: AvailabilityInfo;
  IsAvailableForCustomer: boolean;
  registrationInfoText?: SanityBlock[];
  dynamicRegistrationForm?: {
    title: string;
    body?: SanityBlock[];
    fieldComponents?: FormFieldWithTracking[];
    textField?: SanityBlock[];
    buttonText: string;
    formTrackingId: string;
    options: {
      endpoint?: string;
      httpMethod: 'POST' | 'GET' | 'PATCH' | 'PUT';
      confirmationPage?: {
        buttonText: string;
        body?: SanityBlock[];
      };
      receiptPage?: {
        body?: SanityBlock[];
      };
    };
    transactions: Record<string, any>;
  };
  body: SanityBlock[];
  priceNotMember?: number;
  priceMember?: number;
}

export enum AvailabilityInfo {
  Available = 'Available',
  UserNotLoggedIn = 'UserNotLoggedIn',
  UserNotInLocalDepartment = 'UserNotInLocalDepartment',
  UserNotInMcClub = 'UserNotInMcClub',
}
