import React from 'react';
import { CardGrid } from '@naf/card-grid';
import { Grid, GridCol } from '@naf/grid';
import { useHistory } from 'react-router';
import { BenefitsType } from '../../../../types/benefitsType';
import defaultBenefitImage from '../../../assets/images/defaultLocalBenefit.svg?url';
import { StyledLink } from '../styled-link/StyledLink';

interface Props {
  benefitItems: Record<string, BenefitsType> | BenefitsType[];
  size?: 'standard' | 'small';
  className?: string;
}
const BenefitsGrid = ({ benefitItems, size = 'small', className }: Props) => {
  const benefits = !(benefitItems instanceof Array) ? Object.values(benefitItems) : benefitItems;
  const columns = size === 'small' ? '4' : '6';
  const history = useHistory();
  return (
    <Grid>
      {benefits.map(
        ({
          image,
          name,
          title,
          slug,
          isNewBenefit,
          isCampaign,
          hasActiveCampaign,
          id,
          isLocalContent,
          localBenefitImage,
          ingress,
        }) => {
          const localImage =
            localBenefitImage?.source === 'NafIllustration' ? defaultBenefitImage : localBenefitImage?.url || '';
          return (
            <GridCol s="12" m="12" l={columns} xl={columns} className={className}>
              <StyledLink
                to={
                  isLocalContent
                    ? `${history.location.pathname}/fordeler/${slug}`
                    : `/medlemskap/medlemsfordeler/${slug || id}`
                }
              >
                <CardGrid
                  title={name || title || ''}
                  img={{
                    src: isLocalContent ? localImage : image?.srcSet?.jpgLarge || '',
                    alt: isLocalContent ? localBenefitImage?.caption || '' : image?.altText || '',
                  }}
                  label={
                    isNewBenefit || isCampaign || hasActiveCampaign
                      ? {
                          text: `${isNewBenefit ? 'Ny fordel' : 'Kampanje'}`,
                          variant: `${isNewBenefit ? 'signature' : 'moss'}`,
                        }
                      : undefined
                  }
                >
                  {ingress}
                </CardGrid>
              </StyledLink>
            </GridCol>
          );
        },
      )}
    </Grid>
  );
};

export default BenefitsGrid;
