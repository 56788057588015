import { Text, TextVariant } from '@naf/text';
import React from 'react';
import S from '../Styles';

type Props = {
  count: number;
  selectedCounties: string[];
  name: string;
};

const CountText = ({ count, selectedCounties, name }: Props) => (
  <S.CountText>
    <Text tag="span" variant={TextVariant.BodyText}>
      Viser
    </Text>
    <Text tag="span" variant={TextVariant.BodyTextHeader}>{` ${count} ${name}${count > 1 ? 'er' : ''} ${
      selectedCounties.length > 0
        ? `i ${selectedCounties.join(', ').replace(/, ([^,]*)$/, ' og $1')} `
        : `i alle fylker `
    }`}</Text>
    <Text tag="span" variant={TextVariant.BodyText}>
      alfabetisk
    </Text>
  </S.CountText>
);

export default CountText;
