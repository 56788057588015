import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import React, { useEffect } from 'react';
import { Grid } from '@naf/grid';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from '@naf/tabs';
import { ButtonLink } from '@naf/button-link';
import { Link } from 'react-router-dom';
import { nafColor } from '@nafcore/theme';
import { Button } from '@naf/button';
import { useDocument } from '../../../hooks/useDocument';
import { LoaderContent } from '../../LoaderPage';
import family from '../../../../assets/images/family.svg?url';
import ContactBox from '../components/ContactBox';
import { LocalDepartmentType } from '../../../../../types/localDepartmentType';
import { actions } from '../../../redux/modules/benefits';
import { actions as eventActions } from '../../../redux/modules/nafEvents';
import { actions as localArticleActions } from '../../../redux/modules/localArticles';
import useSelector from '../../../redux/typedHooks';
import EventList from '../components/EventList';
import S from '../Styles';
import { Spinner } from '../../../components/spinner/Spinner';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { useWindowLocation } from '../../../hooks/useWindowLocation';
import { useSendGTMEventOnce } from '../../../hooks/useSendGTMEventOnce';
import ArticleGrid from '../../../components/article-grid/ArticleGrid';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import BenefitsGrid from '../../../components/benefits-list/BenefitsGrid';
import { useHandleNafLocationsRoutes } from '../useHandleNafLocationsRoutes';
import NafLocalEntryCard from '../../../components/entry-cards/NafLocalEntryCard';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import BlockContent from '../../../components/block-content/BlockContent';
import { NotFound } from '../../../loadable-elements/NotFound';

type Props = {
  match: {
    params: { slug: string };
  };
};

const LocalDepartment = ({
  match: {
    params: { slug },
  },
}: Props) => {
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();
  const { data, meta } = useDocument(slug, simpleToken);
  const { benefitsList, isUpdating: isUpdatingBenefits } = useSelector((state) => state.benefits);
  const { monthlyEvents, total, fetchState } = useSelector((state) => state.nafEvents);
  const {
    pages,
    total: totalLocalArticles,
    isUpdating: isUpdatingArticles,
    errorState,
  } = useSelector((state) => state.localArticles);
  const eventsReturned = monthlyEvents.reduce((acc, curr) => acc + curr.events.length, 0);
  const [take, setTake] = React.useState(6);
  const url = useWindowLocation();
  const { locations } = useHandleNafLocationsRoutes();

  useEffect(() => {
    if (dispatch) {
      dispatch(actions.getBenefits.request(`organizer=${slug}`));
      dispatch(eventActions.getNafEvents.request({ organizer: slug, take }));
      dispatch(localArticleActions.getLocalArticles.request(slug));
    }
  }, [take, dispatch, slug]);

  useSendGTMEventOnce(
    data && {
      page_type: 'nafLocationsApp',
      pageCategory: `Her finner du NAF / ${Object.values(locations).find((l) => url.includes(l.fullUrl))?.name} / ${
        (data as LocalDepartmentType).title
      }`,
      contentId: (data as LocalDepartmentType).id,
    },
    [data],
  );

  if (!data && meta.isUpdating) {
    return <LoaderContent />;
  }
  if (!data) {
    return <NotFound />;
  }
  const {
    title,
    ingress,
    body,
    image,
    phoneNumber,
    phoneOpeningHours,
    email,
    facebook,
    seoConfig,
    localReferenceCardList,
  } = data as LocalDepartmentType;
  const noHitText = (
    <>
      {`Det er for tiden ikke lagt ut noen arrangementer fra ${title}. Har du lyst til å engasjere deg lokalt? Ta gjerne kontakt med oss på `}
      <a href={`mailto:${email}`}>{email}</a> for å høre hvordan du kan engasjere deg.
    </>
  );
  const hasBenefits = Object.values(benefitsList).length > 0;
  const hasArticles = totalLocalArticles > 0;
  const isLoadingEventsOrBenefitsOrArticles =
    fetchState === 'initial' ||
    (fetchState === 'isUpdating' && monthlyEvents.length === 0) ||
    isUpdatingBenefits ||
    isUpdatingArticles;
  let selectedTab = 'benefits';
  if (monthlyEvents.length > 0) {
    selectedTab = 'events';
  } else if (hasArticles) {
    selectedTab = 'articles';
  }
  return (
    <LayoutWithMainContent
      title={title}
      description={seoConfig?.introduction || ingress}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoDescription={seoConfig?.introduction || undefined}
      seoTitle={seoConfig?.title || undefined}
      imgUrl={image?.url || family}
      internalSearchMetaData={{ cardType: InternalSearchCardType.LocalDepartment, imageUrl: image?.url }}
      url={url}
    >
      <BreadCrumb />
      <GridWrapper>
        <Header variant={TextVariant.Header1}>{title}</Header>
        <Picture src={image?.url || family} alt={image?.caption || 'familie'} />
        {ingress && <Ingress variant={TextVariant.Ingress}>{ingress}</Ingress>}
        {body && (
          <BodyContainer>
            <BlockContent context="stripped" value={body} />
          </BodyContainer>
        )}
        {facebook ? (
          <Facebook>
            <ButtonLink icon="launch" href={facebook} text="Se siste nytt på Facebook" size="small" target="_blank" />
          </Facebook>
        ) : (
          <FacebookPlaceholder />
        )}
      </GridWrapper>
      <ContactWrapper>
        <StyledContactBox phone={phoneNumber} email={email} phoneOpeningHours={phoneOpeningHours} />
        {localReferenceCardList?.map((card) => (
          <StyledEntryCard key={card.title}>
            <NafLocalEntryCard card={card} />
          </StyledEntryCard>
        ))}
      </ContactWrapper>
      {!hasArticles && !hasBenefits && isLoadingEventsOrBenefitsOrArticles && <Spinner />}
      {(hasBenefits || hasArticles) && (
        <Tabs selectedTab={selectedTab}>
          <TabList>
            <Tab item="events">Arrangementer</Tab>
            {hasBenefits && <Tab item="benefits">Lokale medlemsfordeler</Tab>}
            {(hasArticles || isUpdatingArticles) && <Tab item="articles">Aktuelt</Tab>}
          </TabList>
          <TabPanel item="events">
            <EventList monthlyEvents={monthlyEvents} noHitText={noHitText} />
            {typeof total !== 'undefined' && total > 0 && (
              <S.ShowMoreButtonWrapper>
                {eventsReturned < total && (
                  <Button variant="outline" onClick={() => setTake(Math.min(take + 10, total))}>
                    {fetchState === 'isUpdating' ? <Spinner /> : 'Vis flere'}
                  </Button>
                )}
                <p>{`Viser ${eventsReturned} av ${total} arrangementer`}</p>
              </S.ShowMoreButtonWrapper>
            )}
          </TabPanel>
          {hasBenefits && (
            <TabPanel item="benefits">
              <StyledBenefitsGrid benefitItems={benefitsList} />
              <YellowLine />
              <StyledText variant={TextVariant.Header2}>
                NAF har også fordeler for alle medlemmer i hele landet.
              </StyledText>
              <StyledLink to="/medlemskap/medlemsfordeler">
                <Button type="button">Se alle medlemsfordeler</Button>
              </StyledLink>
            </TabPanel>
          )}
          {(hasArticles || isUpdatingArticles) && (
            <TabPanel item="articles">
              {Object.entries(pages).map(([key, { data: articlePageData, isLoading }]) => (
                <ArticleGrid
                  key={key}
                  articleItems={articlePageData}
                  updating={isLoading}
                  error={!!errorState}
                  showNewsLabel
                />
              ))}
            </TabPanel>
          )}
        </Tabs>
      )}
      {!isLoadingEventsOrBenefitsOrArticles && !(hasBenefits || hasArticles) && (
        <>
          <EventHeader variant={TextVariant.Header2}>Arrangement</EventHeader>
          <EventList monthlyEvents={monthlyEvents} noHitText={noHitText} />
          {typeof total !== 'undefined' && total > 0 && (
            <S.ShowMoreButtonWrapper>
              {eventsReturned < total && (
                <Button variant="outline" onClick={() => setTake(Math.min(take + 10, total))}>
                  {fetchState === 'isUpdating' ? <Spinner /> : 'Vis flere'}
                </Button>
              )}
              <p>{`Viser ${eventsReturned} av ${total} arrangementer`}</p>
            </S.ShowMoreButtonWrapper>
          )}
        </>
      )}
    </LayoutWithMainContent>
  );
};

const GridWrapper = styled(Grid)`
  grid-gap: 0;
  column-gap: ${spacing.space32};

  h1 {
    margin-top: ${spacing.space24};
    margin-bottom: -${spacing.space8};
  }

  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: 1fr;
  }
`;

const ContactWrapper = styled(Grid)`
  grid-gap: 0;
  column-gap: ${spacing.space32};
  margin-bottom: ${spacing.space80};

  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: 1fr;
    gap: ${spacing.space32};
  }
`;

const FacebookPlaceholder = styled.div`
  grid-column: 1 / 6;
  height: ${spacing.space32};
  margin-bottom: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

const EventHeader = styled(Text)`
  margin: ${spacing.space64} 0 ${spacing.space32} 0;
`;

const Facebook = styled.div`
  grid-column: 1 / 6;
  margin-bottom: ${spacing.space40};
  @media (max-width: ${breakpoints.m}) {
    grid-column: 1;
  }
`;

const Header = styled(Text)`
  grid-column: 1 / 10;
  @media (max-width: ${breakpoints.m}) {
    grid-row: 2;
    grid-column: 1;
  }
`;

const YellowLine = styled.div`
  background-color: ${nafColor.signature.yellow};
  width: 126px;
  height: 4px;
  margin-bottom: ${spacing.space56};
`;

const Ingress = styled(Text)`
  grid-column: 1 / 10;
  grid-row: 2;
  font-style: italic;

  @media (max-width: ${breakpoints.m}) {
    grid-row: 3;
    grid-column: 1;
  }
`;

const BodyContainer = styled.div`
  grid-column: 1 / 10;
  margin: 0 0 ${spacing.space32} 0;
  @media (max-width: ${breakpoints.m}) {
    grid-column: 1;
  }
`;

const Picture = styled.img`
  grid-row: 1/4;
  grid-column: 10/13;
  justify-self: end;
  height: 253px;
  width: 100%;
  margin-top: ${spacing.space32};
  object-fit: contain;

  @media (max-width: ${breakpoints.m}) {
    grid-row: 1;
    grid-column: 1;
    justify-self: start;
    width: 100%;
    margin-top: ${spacing.space24};
  }
`;

const StyledContactBox = styled(ContactBox)`
  grid-column: 1 / 5;
  background-color: ${nafColor.primary.dew};

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1;
  }
`;

const StyledEntryCard = styled.div`
  grid-column: auto / span 4;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1;
  }
`;

const StyledBenefitsGrid = styled(BenefitsGrid)`
  margin-bottom: ${spacing.space40};
`;

const StyledText = styled(Text)`
  margin-bottom: ${spacing.space24};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default LocalDepartment;
