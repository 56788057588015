import React, { ReactNode, useContext } from 'react';
import { Checkbox } from '@naf/checkbox';
import { TextVariant } from '@naf/text';
import xor from 'lodash/xor';
import { Message } from '@naf/input';
import ArrowHoverLink from '../components/ArrowHoverLink';
import { Spinner } from '../../../components/spinner/Spinner';
import S from '../Styles';
import { LocationSlugs, useGetNafLocations } from '../../../hooks/useGetNafLocations';
import CountText from '../components/CountText';
import { NafLoactionSlugs, useHandleNafLocationsRoutes } from '../useHandleNafLocationsRoutes';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { NafLocationsAppContext } from '../NafLocationsApp';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { useWindowLocation } from '../../../hooks/useWindowLocation';
import LocationChips from '../components/LocationChips';

const LocalDepartments = () => {
  const data = useContext(NafLocationsAppContext);
  const { seoConfig } = data || {};
  const url = useWindowLocation();

  const [selectedCounties, setSelectedCounties] = React.useState<string[]>([]);
  const { filteredLocations, nafLocationsByCounty, fetchState, error, totalCountInSelectedCounties, isFiltering } =
    useGetNafLocations(LocationSlugs.lokalavdeling, selectedCounties, 1000);
  const { locations } = useHandleNafLocationsRoutes();

  let content: ReactNode | null;

  if (fetchState === 'initial' || (fetchState === 'isUpdating' && nafLocationsByCounty.length === 0)) {
    content = <Spinner />;
  } else if (error) {
    content = <Message error>{error.message}</Message>;
  } else {
    content = (
      <S.Wrapper>
        <CountText
          count={totalCountInSelectedCounties as number}
          selectedCounties={selectedCounties}
          name="Lokalavdeling"
        />
        <S.Filter>
          <S.FilterHeader variant={TextVariant.BodyTextHeader}> Fylke</S.FilterHeader>
          <Checkbox
            name="alle"
            checked={selectedCounties.length === 0}
            onChange={() => setSelectedCounties([])}
            label="Alle"
          />
          {nafLocationsByCounty.map(({ county }) => {
            const checked = selectedCounties.includes(county.slug);
            return (
              <Checkbox
                key={county.name}
                name={county.slug}
                checked={checked}
                disabled={county.total === 0}
                onChange={() => {
                  setSelectedCounties(xor(selectedCounties, [county.slug]));
                }}
                label={`${county.name} (${county.total})`}
              />
            );
          })}
        </S.Filter>
        <S.HitList>
          {fetchState === 'isUpdating' && isFiltering ? (
            <Spinner />
          ) : (
            <>
              {filteredLocations.map(({ county, nafLocations }) => (
                <div key={county.name}>
                  <S.ResultHeader variant={TextVariant.BodyTextHeader}>{county.name}</S.ResultHeader>
                  {nafLocations.map((location) => (
                    <S.BorderWrapper key={location.link.data.slug}>
                      <ArrowHoverLink
                        variant={TextVariant.Header2}
                        to={`${locations[NafLoactionSlugs.localDepartment].fullUrl}/${location.link.data.slug}`}
                        text={location.title}
                      />
                    </S.BorderWrapper>
                  ))}
                </div>
              ))}
            </>
          )}
        </S.HitList>
      </S.Wrapper>
    );
  }
  return (
    <LayoutWithMainContent
      title="Lokalavdelinger"
      description="Liste over NAF sine lokalavdelinger"
      seoDescription="Liste over NAF sine lokalavdelinger"
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle="Liste over NAF sine lokalavdelinger"
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      url={url}
    >
      <LocationChips />
      {content}
    </LayoutWithMainContent>
  );
};

export default LocalDepartments;
