import { useLocation } from 'react-router';
import { SiteStructureType } from '../../../../types/siteStructureType';
import { useSiteStructure } from '../../hooks/useSiteStructure';

export enum NafLoactionSlugs {
  localDepartment = 'lokalavdelinger',
  mcClubs = 'mc-klubber',
  nafCenter = 'naf-senter',
  practiceTracks = 'naf-ovingsbaner',
  events = 'arrangementer',
}

const locations: { name: string; slug: NafLoactionSlugs }[] = [
  { name: 'Lokalavdelinger', slug: NafLoactionSlugs.localDepartment },
  { name: 'MC-klubb', slug: NafLoactionSlugs.mcClubs },
  { name: 'NAF Senter', slug: NafLoactionSlugs.nafCenter },
  { name: 'Øvingsbaner', slug: NafLoactionSlugs.practiceTracks },
  { name: 'Arrangementer', slug: NafLoactionSlugs.events },
];

type LocationsMapType = Record<NafLoactionSlugs, { slug: NafLoactionSlugs; fullUrl: string; name: string }>;
type ReturnType = { locations: LocationsMapType } & SiteStructureType;

// enrich the static locations array above with the fullUrl from the siteStructure
export const useHandleNafLocationsRoutes = (): ReturnType => {
  const { pathname } = useLocation();
  const { data } = useSiteStructure(pathname.split('/')[1]) || {};
  const nafLocationPath = data?.slug;
  return {
    locations: locations.reduce((acc, curr) => {
      acc[curr.slug] = { ...curr, fullUrl: `/${nafLocationPath}/${curr.slug}` };
      return acc;
    }, {} as LocationsMapType),
    ...data,
  };
};
