import { Text, TextVariant } from '@naf/text';
import React from 'react';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { Phone } from '@styled-icons/material-outlined/Phone';
import { Email } from '@styled-icons/material-outlined/Email';
import { fontStyle, spacing } from '@naf/theme';

type Props = {
  email: string;
  phone?: string;
  phoneOpeningHours?: string;
  className?: string;
};

const ContactBox = ({ email, phone, phoneOpeningHours, className }: Props) => (
  <Wrapper className={className}>
    <Text variant={TextVariant.BodyTextHeader}>Kontakt</Text>
    <InfoLine>
      <StyledEmail height={16} width={16} />
      <a href={`mailto:${email}`}>{email}</a>
    </InfoLine>
    {phone && (
      <InfoLine>
        <Phone height={16} width={16} />
        <a href={`tel:${phone}`}>
          {phone
            .split(/(\d{2})/)
            .join(' ')
            .trim()}
        </a>
      </InfoLine>
    )}
    {phoneOpeningHours && <Text variant={TextVariant.BodyText}>{`Åpningstider telefon: ${phoneOpeningHours}`}</Text>}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space16};
  border: 1px solid ${nafColor.neutral.neutral2};
  padding: ${spacing.space24};

  > * {
    margin: 0;
  }
`;

const InfoLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.space8};
  ${fontStyle.bodyText.bodyText};
`;

const StyledEmail = styled(Email)`
  flex-shrink: 0;
`;

export default ContactBox;
