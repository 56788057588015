import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { Grid, GridCol } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';
import { ArrowForward } from '@styled-icons/material/ArrowForward';
import { Circle } from '@styled-icons/material/Circle';
import { Button } from '@naf/button';
import { nafColor } from '@nafcore/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NafEvents } from '../../../../../types/nafEvents';
import { getMonthAndYear } from '../../../utils/Dates';
import calendarImage from '../../../../assets/images/Calender.png';
import OrganizerHeader from './OrganizerHeader';
import S from '../Styles';
import EventDetails from './EventDetails';
import EventImage from './EventImage';
import { NafLoactionSlugs, useHandleNafLocationsRoutes } from '../useHandleNafLocationsRoutes';

type Props = {
  monthlyEvents: NafEvents[];
  noHitText: React.ReactElement;
  button?: React.ReactElement;
};

const EventList = ({ monthlyEvents, noHitText, button }: Props) => {
  const { locations } = useHandleNafLocationsRoutes();
  return (
    <>
      {monthlyEvents.map(({ events, month }) => (
        <MonthWrapper key={`${month.month}-${month.year}`}>
          <S.IconHeader>
            <GreenCircle height={14} width={14} />
            <Text variant={TextVariant.Tag}> {getMonthAndYear(month)}</Text>
          </S.IconHeader>
          <StyledGrid>
            {events.map(
              ({
                title,
                ingress,
                dateTimeIntervals,
                organizer,
                link,
                locationName,
                locationAddress,
                coordinates,
                image,
              }) => {
                const linkTo = `${locations[NafLoactionSlugs.events].fullUrl}/${link.data.slug}`;
                return (
                  <React.Fragment key={linkTo}>
                    <GridCol s="12" m="6" l="6" xl="5">
                      <Link to={linkTo}>
                        <StyledImage image={image} />
                      </Link>
                    </GridCol>
                    <GridCol s="12" m="6" l="6" xl="5">
                      {organizer && <OrganizerHeader organizer={organizer} />}
                      <TitleLink to={linkTo}>
                        <S.TextNoMargin variant={TextVariant.Header2}> {title} </S.TextNoMargin>
                      </TitleLink>
                      <Ingress variant={TextVariant.BodyText}>{ingress}</Ingress>
                      <EventDetails
                        dateTimeIntervals={dateTimeIntervals}
                        locationName={locationName}
                        locationAddress={locationAddress}
                        coordinates={coordinates}
                      />
                      <LinkButton type="button" variant="secondary" color={nafColor.primary.moss} isNotClickable>
                        <LinkContent to={linkTo}>
                          <Text variant={TextVariant.BodyTextSubHeader}> Gå til arrangement</Text>
                          <ArrowForward height={16} width={16} />
                        </LinkContent>
                      </LinkButton>
                    </GridCol>
                  </React.Fragment>
                );
              },
            )}
          </StyledGrid>
        </MonthWrapper>
      ))}
      {monthlyEvents.length === 0 && (
        <NoHitWrapper>
          <StyledCalendarImage src={calendarImage} alt="tom kalender" />
          <NoHitHeader variant={TextVariant.Header2}>Ingen arrangementer er lagt ut</NoHitHeader>
          <NoHitText variant={TextVariant.BodyText}>{noHitText}</NoHitText>
          {button}
        </NoHitWrapper>
      )}
    </>
  );
};

const MonthWrapper = styled.div`
  margin-top: ${spacing.space56};
`;

const NoHitWrapper = styled.div`
  margin-top: ${spacing.space56};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoHitHeader = styled(Text)`
  margin-bottom: ${spacing.space16};
`;

const NoHitText = styled(Text)`
  margin-bottom: ${spacing.space24};
  margin-top: 0;
  max-width: 350px;
  text-align: center;
`;

const StyledCalendarImage = styled.img`
  width: 256px;
  height: auto;
  border-radius: ${spacing.space8};
  margin-bottom: ${spacing.space32};
  margin-top: ${spacing.space64};

  @media (max-width: ${breakpoints.s}) {
    width: 150px;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  row-gap: ${spacing.space32};

  @media (min-width: ${breakpoints.m}) {
    row-gap: ${spacing.space80};
  }
  @media (min-width: ${breakpoints.l}) {
    row-gap: ${spacing.space48};
  }
`;

const GreenCircle = styled(Circle)`
  color: #267c4f;
`;

const StyledImage = styled(EventImage)`
  width: 100%;
  min-height: 320px;
  height: auto;
  border-radius: ${spacing.space8};
  flex-shrink: 0;
  object-fit: cover;

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space16};
  }

  @media (max-width: ${breakpoints.m}) {
    min-height: 250px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 496px;
  }
`;

const Ingress = styled(Text)`
  margin: ${spacing.space8} 0 ${spacing.space32} 0;
`;

const LinkButton = styled(Button)`
  height: ${spacing.space40};
  padding: 0 ${spacing.space16};
  text-decoration: none;
`;

const LinkContent = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;

  > svg {
    margin-left: ${spacing.space8};
  }
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

export default EventList;
