import React from 'react';
import { CardNavigation } from '@naf/cards';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { InternalLinkType } from '../../../../types/internalLinkType';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { ExternalLinkType } from '../../../../types/externalLinkType';

type Props = {
  card: {
    title: string;
    ingress: string;
    externalLink: {
      data: ExternalLinkType;
    };
    internalLink: { data: InternalLinkType };
  };
};

const NafLocalEntryCard = ({ card: { internalLink, externalLink, ingress, title } }: Props) => {
  const internalItemUrl = useDocumentUrlWithNode(internalLink?.data);
  return internalItemUrl ? (
    <Link to={internalItemUrl}>
      <StyledCardNavigation title={title}>{ingress}</StyledCardNavigation>
    </Link>
  ) : (
    <StyledCardNavigation title={title} href={externalLink?.data?.href} target="_blank">
      {ingress}
    </StyledCardNavigation>
  );
};

const StyledCardNavigation = styled(CardNavigation)`
  && {
    width: 100%;
    height: 100%;
  }
`;

export default NafLocalEntryCard;
